var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$store.state.valorEnviado)?_c('v-navigation-drawer',_vm._b({attrs:{"id":"core-navigation-drawer","dark":_vm.barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',"expand-on-hover":_vm.expandOnHover,"src":_vm.barImage,"mobile-breakpoint":"1920","app":"","width":"260"},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({attrs:{"gradient":`to top, ${_vm.barColor}`}},'v-img',props,false))]}},{key:"append",fn:function(){return [_c('base-item',{staticClass:"text-h1",attrs:{"item":{
        icon: 'mdi-lock',
        title: 'Cambiar clave',
        to: '/clave',
      }}}),_c('base-item',{staticClass:"text-subtitle-1",attrs:{"item":{
        title: 'Cerrar sesión',
        icon: 'mdi-account',
        to: '/cerrar'
      }}})]},proxy:true}],null,false,3305103341),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('v-divider',{staticClass:"mb-1"}),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"color":"white","rounded":"","size":"30"}},[_c('v-img',{attrs:{"src":"assets/favicon.png"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4",attrs:{"v-text":_vm.titulo}},[_vm._v(" "+_vm._s(_vm.nombre)+" "),_c('v-list-item-title',{staticClass:"text-h3",attrs:{"v-text":_vm.titulo}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-list',{attrs:{"expand":"","nav":""}},[_c('div'),_vm._l((_vm.itemsFinal),function(item,i){return _c('div',[_c('base-item',{key:item.orden,attrs:{"item":item}})],1)})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }