<template>
  <v-navigation-drawer
    v-if="!$store.state.valorEnviado"
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-breakpoint="1920"
    app
    width="260"
    v-bind="$attrs"
  >
    <template #img="props">
      <v-img
        :gradient="`to top, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          rounded
          size="30"
        >
          <v-img
            src="assets/favicon.png"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            :v-text="titulo"
          >
            {{ nombre }}
            <v-list-item-title
              class="text-h3"
              :v-text="titulo"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <div />
      <!-- eslint-disable-next-line -->
        <div v-for="(item, i) in itemsFinal">
        <base-item
          :key="item.orden"
          :item="item"
        />
      </div>
    </v-list>
    <template #append>
      <base-item
        class="text-h1"
        :item="{
          icon: 'mdi-lock',
          title: 'Cambiar clave',
          to: '/clave',
        }"
      />
      <base-item
        class="text-subtitle-1"
        :item="{
          title: 'Cerrar sesión',
          icon: 'mdi-account',
          to: '/cerrar'
        }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      titulo: 'CSJLA',
      nombre: '',
      dataUsuario: '',
      itemsFinal: [],
      items: [],
      itemsTotal: [{
                     icon: 'mdi-server',
                     title: 'Servidores',
                     subtitle: 'Consulta los servidores desplegados',
                     to: '/servidor',
                     orden: 1,
                   },
                   {
                     icon: 'mdi-database-search',
                     title: 'Servicios',
                     subtitle: 'Consulta los servicios desplegados',
                     to: '/servicio',
                     orden: 2,
                   }],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.items = []
          this.dataUsuario = JSON.parse(localStorage.getItem('usuarioAlien')) ? JSON.parse(localStorage.getItem('usuarioAlien')) : ''
          this.nombre = JSON.parse(localStorage.getItem('usuarioAlien')).nombre ? JSON.parse(localStorage.getItem('usuarioAlien')).nombre : ''
          this.dataUsuario.rutas.split(',').forEach(element => {
          this.itemsTotal.find(o => {
            if (o.to === element) {
              this.items.push(o)
            }
          })
        })
        // this.items.sort((a, b) => a.orden.localeCompare(b.orden))
        this.items.sort((a, b) => a.orden > b.orden ? 1 : -1)
        // this.items.sort(function (a, b) { return b.orden - a.orden })
        // console.log(aa)
        this.itemsFinal = this.items
        this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>
